import checkListPdf from '../../assets/files/check-list.pdf';
import traderDiaryPdf from '../../assets/files/trader-diary.pdf';

export const ACTIVE_LESSON = [
  {
    id: 1,
    name: 'Криптовалюты. Вводный урок.',
    video: 'https://player.vimeo.com/video/539039944',
    des: '<b>В данном видео вы ознакомитесь со структурой обучения и получите рекомендации по его прохождению.</b>',
  },
  {
    id: 2,
    name: 'Криптовалюты. Урок 1',
    video: 'https://player.vimeo.com/video/543110814',
    des: '<b>Что такое криптовалюты?</b><ul><li>Определение криптовалют.</li><li>	Технология блокчейн.</li><li>	Истории возникновения криптовалют.</li><li>	Основные участники рынка.</li><li>	Сервисы и платформы, которые помогут нам в работе с криптовалютами.</li><li>	Где и как можно купить и обменять криптовалюты.</li><li>Ссылка на наш телеграмм-бот (обменник) - <a href="https://t.me/RSI_garantBot">t.me/RSI_garantBot</a></li></ul>',
  },
  {
    id: 3,
    name: 'Криптовалюты. Урок 2',
    video: 'https://player.vimeo.com/video/560399420',
    des: '<b>Функционал биржи бинанс</b>  <ul><li>Как выгоднее купить криптовалюту</li><li>Спотовая торговля</li><li>Маржинальная торговля</li><li>Торговля фьючерсами</li><li>Стейкинг</li><li>Стратегии</li></ul>',
  },
  {
    id: 4,
    name: 'Криптовалюты. Урок 3',
    video: 'https://player.vimeo.com/video/561533282',
    des: '<b>Стратегия торговли по тренду</b><ul><li>Уровни</li><li>Тренды</li><li>Скользящее среднее</li><li>Объёмы</li><li>Вход в сделку</li><li>Выход из сделки</li><li>Определение риска</li></ul>',
  },
  {
    id: 5,
    name: 'Криптовалюты. Урок 4',
    video: 'https://player.vimeo.com/video/587444995?h=5170cb7aaf',
    des: '<b>Децентрализованные кошельки</b><ul><li>Регистрация и установка</li><li>Кошелек MetaMask</li></ul>',
  },
  {
    id: 6,
    name: 'Криптовалюты. Урок 5',
    video: 'https://player.vimeo.com/video/587447742?h=adb6758d2e',
    des: '<b>Децентрализованные биржи</b><ul><li>Регистрация </li><li>Покупка токенов</li><li>Биржа Uniswap</li><li>Биржа PancakeSwap</li></ul>',
  },
  {
    id: 7,
    name: 'Криптовалюты. Урок 6',
    video: 'https://player.vimeo.com/video/587451093?h=73a28b68f9',
    des: '<b>Как зарабатывать на DEX</b><ul><li>Фарминг</li><li>Стейкинг</li><li>Стратегия на бирже PancakeSwap</li></ul>',
  },
  {
    id: 8,
    name: 'Трейдинг. Урок 1',
    video: 'https://player.vimeo.com/video/582856240',
    des: '<b>Технический анализ </b><ul><li>История</li><li>Основные инструменты</li><li>Применение</li></ul><br/><br/> Вашему вниманию - дневник и важный чек-лист трейдера от команды управляющих RSI. Сохраняйте себе, чтобы не потерять',
    files: [
      {
        name: 'Чек-лист трейдера',
        path: checkListPdf,
      },
      {
        name: 'Дневник трейдера',
        path: traderDiaryPdf,
      },
    ],
  },
  {
    id: 9,
    name: 'Трейдинг. Урок 2',
    video: 'https://player.vimeo.com/video/582866390',
    des: '<b>Риск-менеджмент</b><br/><ul><li>Расчёт позиции для сделки</li><li>Риск</li><li>Соотношение риск/прибыль</li></ul>',
  },
];
