import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import confidencialPolitic from '../../../assets/files/privacy-policy.pdf';
import userPoliticy from '../../../assets/files/terms.pdf';
import logo from '../../../assets/images/logo-horizontal.svg';
import { useFetch } from '../../../hooks/useFetch';
import useUrl from '../../../hooks/useUrl';
import { Icon } from '../../common/icon';
import { Button } from '../../common/ui/button';
import { Modal } from '../../common/ui/modal';
import './index.scss';

export const FastLaunchLayout = ({ children }) => {
  const { referral } = useUrl();
  const [openText, setOpenText] = useState(false);
  const { response: getCuratorData } = useFetch({
    url: `/auth/curator?ref=${referral}`,
  });
  const [modalVisible, setModalVisible] = useState(false);

  const openContactModal = () => {
    setModalVisible(true);
  };

  const closeContactModal = () => {
    setModalVisible(false);
  };

  const toggleText = () => {
    setOpenText(!openText);
  };

  return (
    <>
      <Container id="page-fast-launch">
        <Row className="header py-5 items-center justify-between">
          <Col xs="auto">
            <img alt="RSI logo" src={logo} />
          </Col>

          <Col xs="auto">
            <Link to={`/login?referral=${referral ? referral : ''}`}>
              <Button color="primary mt-4 md:mt-0 mr-2">Войти</Button>
            </Link>

            <Link to={`/registration?referral=${referral ? referral : ''}`}>
              <Button color="primary mt-4 md:mt-0">Зарегистрироваться</Button>
            </Link>
          </Col>
        </Row>

        <Row className="mt-10 mb-10 lg:mb-20">{children}</Row>

        <Row className="footer text-xs ">
          <Col xs={12}>
            <p className={'notification-text relative'}>
              <p className={` ${openText ? '' : 'small-text'}`}>
                RISK WARNING: Trading and investing in digital assets involves
                substantial risk of loss and is not suitable for all types of
                investors. Please make sure you are investing mindfully after
                understanding the nature, complexity, and risks inherent in the
                trading of digital assets. You should not purchase digital
                assets unless you understand the extent of your exposure to
                potential loss. The price of digital assets can be affected by
                many factors, including, but not limited to, national and
                international economic, financial, regulatory, political,
                terrorist, military, and other events. Extreme changes in price
                may occur at any time, resulting in a potential loss of value of
                your entire investment in digital assets, complete or partial
                loss of purchasing power, and difficulty or a complete inability
                to sell or exchange your digital assets. Please make sure you
                are not risking funds you can not afford to lose. In no event
                shall RSI CAPITAL will be liable to any loss or damage of any
                kind incurred as a result of the use of this site or the
                services found at this site. RSI CAPITAL does not accept any
                customers from and does not operate in any of the following
                restricted countries, such as: USA, Japan, Cambodia, Botswana,
                Ethiopia, Ghana, Pakistan, Sri Lanka, Trinidad and Tobago,
                Tunisia, Iran, Syria, Democratic People’s Republic of Korea,
                Yemen. rsi.capital is owned and operated by RSI CAPITAL.
              </p>
              <p className={'relative block md:hidden'} onClick={toggleText}>
                {openText ? 'Скрыть текст' : 'Читать полностью...'}
              </p>
            </p>{' '}
          </Col>

          <Col className="col-12 py-5">
            <Row className="bottom">
              <Col xs="auto">© 2020, rsi.capital</Col>

              <Col xs="auto">
                <a
                  href={confidencialPolitic}
                  target="_blank"
                  rel="noreferrer"
                  download=""
                >
                  Политика конфеденциальности
                </a>
              </Col>

              <Col xs="auto">
                <a
                  href={userPoliticy}
                  download=""
                  target="_blank"
                  rel="noreferrer"
                >
                  Пользовательское соглашение
                </a>
              </Col>
            </Row>
          </Col>
        </Row>

        {getCuratorData?.data && (
          <button
            onClick={openContactModal}
            id="fast-launch-btn"
            className="z-10 fixed bottom-4 right-4 rounded-full w-14 h-14 bg-purpleDark"
          >
            <Icon size={24} name="phone" color="#fff" className="pos-abs" />
          </button>
        )}
      </Container>

      <Modal
        isOpen={modalVisible}
        onClose={closeContactModal}
        title="Связь с куратором"
      >
        <div className="flex flex-col">
          <a>
            <Button color="default w-full mb-2">
              {getCuratorData?.data?.last_name}{' '}
              {getCuratorData?.data?.first_name}{' '}
              {getCuratorData?.data?.patronymic}
            </Button>
          </a>
          <a href={`tel:+${getCuratorData?.data?.phone}`}>
            <Button color="default w-full mb-2">
              +{getCuratorData?.data?.phone}
            </Button>
          </a>
        </div>
      </Modal>
    </>
  );
};
FastLaunchLayout.propTypes = {
  children: PropTypes.any,
};
