import { UPDATE_PROFILE, UPDATE_WALLET } from './types';

export const updateProfile = userData => {
  return async dispatch => {
    localStorage.setItem('userData', JSON.stringify(userData));

    dispatch({
      type: UPDATE_PROFILE,
      payload: userData,
    });
  };
};

export const updateWalletCount = wallet => {
  return {
    type: UPDATE_WALLET,
    payload: wallet,
  };
};
