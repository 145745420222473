import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactInputMask from 'react-input-mask';

export const Input = ({
  fieldClass,
  inputClass,
  groupText = null,
  groupTextAlign = 'left',
  groupTextClick = null,
  label,
  error,
  name,
  register,
  thumbText,
  readOnly,
  max,
  mask = false,
  maskText = '',
  ...props
}) => {
  const onClick = () => {
    if (groupTextClick) groupTextClick();
  };

  return (
    <label className={cx(fieldClass, 'form-field block')}>
      {label && <div className="label cursor-pointer mb-1">{label}</div>}

      <span className={cx(inputClass, 'input-group flex')}>
        {groupText && groupTextAlign === 'left' && (
          <span
            className={cx(
              'group-prepend flex items-center justify-center _left',
              {
                'cursor-pointer': groupTextClick,
              }
            )}
            onClick={onClick}
          >
            {groupText}
          </span>
        )}
        {mask ? (
          <ReactInputMask
            className="input w-full"
            {...(register && register(name))}
            {...props}
            alwaysShowMask={false}
            mask={maskText}
            maskPlaceholder={''}
          />
        ) : (
          <input
            className="input w-full"
            {...(register && register(name))}
            readOnly={readOnly}
            {...props}
            max={max ? max : ''}
          />
        )}

        {groupText && groupTextAlign === 'right' && (
          <span
            className={cx(
              'group-prepend flex items-center justify-center _right',
              {
                'cursor-pointer': groupTextClick,
              }
            )}
            onClick={onClick}
          >
            {groupText}
          </span>
        )}
      </span>

      {thumbText && <div className="thumb mt-1">{thumbText}</div>}

      {error &&
        (Array.isArray(error) ? (
          error.map((e, index) => (
            <div key={index} className="error mt-1">
              {e}
            </div>
          ))
        ) : (
          <div className="error mt-1">{error}</div>
        ))}
    </label>
  );
};
Input.propTypes = {
  groupText: PropTypes.any,
  groupTextAlign: PropTypes.string,
  fieldClass: PropTypes.any,
  register: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.any,
  thumbText: PropTypes.string,
  groupTextClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  readOnly: PropTypes.any,
  max: PropTypes.string,
  inputClass: PropTypes.string,
  mask: PropTypes.bool,
  maskText: PropTypes.any,
};
