import axios from 'axios';
import { store } from '../store';
import { funcError500, updateLoadingStatus } from '../store/app/actions';
import { errorHandler } from '../utils/errorHandler';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 100000,
});

Api.interceptors.request.use(function (config) {
  const { authToken } = store.getState().app;

  if (config.headers['AppLoading']) {
    store.dispatch(updateLoadingStatus(true));
  }

  if (authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
});

Api.interceptors.response.use(
  function (response) {
    const { appLoading } = store.getState().app;

    if (appLoading) {
      store.dispatch(updateLoadingStatus(false));
    }

    return response;
  },
  error => {
    if (error.response.status === 500) {
      window.location.pathname = '/page500';

      // errorHandler({
      //   errorText:
      //     'Произошла ошибка, попробуйте снова. Если ошибка сохраняется, обратитесь в поддержку.',
      // });
    } else {
      errorHandler({
        errorText: error.message,
      });
    }

    return Promise.reject(error);
  }
);

export const apiPost = async ({ url, postData = null, headerList = {} }) => {
  const res = await Api.post(url, postData, { headers: headerList });

  if (res?.data?.status !== 200 && res?.data?.status !==406) {
    store.dispatch(funcError500(res?.data));
    setTimeout(() => store.dispatch(funcError500(false)), 10000);
  }

  return res.data;
};

export const apiGet = async ({ url, headerList = {} }) => {
  const res = await Api.get(url, { headers: headerList });
  if (res?.data?.status !== 200 && res?.data?.status !==406) {
    store.dispatch(funcError500(res?.data));
    setTimeout(() => store.dispatch(funcError500(false)), 10000);
  }

  return res.data;
};
