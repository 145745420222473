/* eslint-disable indent */
import { UPDATE_PROFILE, UPDATE_WALLET } from './types';

const initialState = {
  profile: {},
  editCity: null,
  coordinates: null,
  wallet: null,
  // countNotifications: "0",
};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROFILE:
      return { ...state, profile: { ...state.profile, ...payload } };

    case UPDATE_WALLET:
      return { ...state, wallet: payload };

    default:
      return state;
  }
};
